import React from "react"
//import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Gallery } from "gatsby-theme-gallery"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome to Chileno</h1>
    <Gallery />
  </Layout>
)

export default IndexPage
